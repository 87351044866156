<template>
   <v-card tile flat height="100%"  width="100%">
    <v-navigation-drawer permanent  class="zamuPurple">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="admin-title my-6">
            Admin
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense  nav class="admin-nav" >
        <v-list-item  v-for="item in items"  :key="item.title"  @click="changeRoute(item)" :class="{'active-link' : item.link === routeName}" class="test">
          <v-list-item-icon class="mr-2">
            <v-icon color="white">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import coreMixin from '@/mixins/coreMixin';

export default {
    name: 'Sidebar',
    mixins: [coreMixin],

    data () {
      return {
        items: [
          { title: 'Users', icon: 'mdi-account-group', link: 'admin/users', route: 'users' },
          { title: 'Team', icon: 'mdi-account', link: 'admin/team', route: 'team' },
          { title: 'Contacts', icon: 'mdi-contacts', link: 'admin/contacts', route: 'contacts' },
          // { title: 'Testimonials', icon: 'mdi-help-box', link: 'admin/users', route: 'users' },
          { title: 'Blog', icon: 'mdi-help-box', link: 'admin/blog-admin', route: 'blog-admin' },
          { title: 'FAQs', icon: 'mdi-help-box', link: 'admin/FAQs', route: 'admin-FAQs' },
          { title: 'Countries', icon: 'mdi-help-box', link: 'admin/countries', route: 'countries' },
          { title: 'Payment Volumes', icon: 'mdi-help-box', link: 'admin/payment-volumes', route: 'volumes' },
          { title: 'Sales Contacts', icon: 'mdi-help-box', link: 'admin/sales-contacts', route: 'salesContacts' },





        ],
        right: null,
      }
    },
    methods: {
      changeRoute(item) {
        this.$router.push({ name: item.route })


      },
    },

}
</script>

<style>

</style>